
import { Duration } from '@/utils/date-time-utils'

export default {
  name: 'ProfileBalance',
  props: {
    balance: {
      type: Duration,
      default: () => new Duration(0),
    },
  },
}
