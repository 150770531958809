
import BaseButton from '@/components/buttons/BaseButton.vue'

export default {
  name: 'MenuButton',
  components: { BaseButton },
  emits: {
    input: (value) => typeof value === 'boolean',
    'menu-button-click': (value) => typeof value === 'boolean',
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onMenuButtonClick() {
      this.$emit('input', !this.value)
      this.$emit('menu-button-click', this.value)
    },
  },
}
