
export default {
  head() {
    return {
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow'
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `${process.env.browserRootURL}` + this.$route.fullPath.slice(1)
        }
      ]
    }
  },
  name: 'default-layout',
  middleware({route, store, redirect}) {
    if (route.query.id && !store.getters.isAuthenticated) return redirect('/account')
  },
  components: {
    SideBar: () => import('@/components/SideBar')
  }
}
