import {PaginatedEndpoint} from '@/services/api'
import {API_V1_PREFIX} from "@/constants/api";

export const state = () => ({
  member: null
})

export const mutations = {
  SET_TEAM_MEMBER(state, payload) {
    state.member = payload
  }
}

export const actions = {
  async getMyTeamMemberInfo({commit}, {id}) {
    const member = await this.$axios.$get(`${API_V1_PREFIX}team/get-my-team-member-info/${id}/`)
    commit('SET_TEAM_MEMBER', member)
    return member
  },

  async depositMinutesToTeamMember(ctx, {id, minutes}) {
    return await this.$axios.put(`${API_V1_PREFIX}team/deposit-minutes-to-team-member/${id}/`, {minutes}).catch(error => error.response)
  },

  async withdrawMinutesFromTeamMember(ctx, {id, minutes}) {
    return await this.$axios.put(`${API_V1_PREFIX}team/withdraw-minutes-from-team-member/${id}/`, {minutes}).catch(error => error.response)
  }
}


export const getters = {
  member: state => state.member
}
