
import { required, minLength, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import auth_service from '@/services/auth.service'
import { API_V1_PREFIX } from '@/constants/api'

export default {
  name: 'account',
  layout: 'auth',
  mixins: [validationMixin],
  data: () => ({
    login_form: {
      email: '',
      password: '',
    },
    status: null,
    isVisibleErrorText: false,
    socialError: false,
  }),

  validations: {
    login_form: {
      email: { required, email },
      password: { required, minLength: minLength(8) },
    },
  },
  components: {
    KitButton: () => import('@/components/Button'),
    LayoutAuth: () => import('@/components/LayoutAuth/index.vue'),
    MobileAuthHeader: () => import('@/components/MobileAuthHeader'),
    GoogleButton: () => import('@/components/Button/Google'),
    FacebookButton: () => import('@/components/Button/Facebook'),
  },
  computed: {
    isDisabledBtn() {
      if (this.login_form.email && this.login_form.password) {
        return false
      }
    },
  },
  methods: {
    makeData() {
      return {
        email: this.login_form.email,
        password: this.login_form.password,
      }
    },
    async handleSubmitForm() {
      this.$v.login_form.$touch()
      if (this.$v.login_form.$invalid) return
      await this.$auth.logout()
      let data_user = this.makeData()
      try {
        this.isVisibleErrorText = false
        await this.$auth.loginWith('local', { data: data_user })
        await this.redirectToAccountPage()
      } catch (error) {
        if (error.response.status === 403) this.$toast.errors('You are authorized in admin panel')
        this.isVisibleErrorText = true
      }
    },
    async redirectToAccountPage() {
      if (this.$auth.user['is_main_user'] || this.$auth.user['is_sub_user']) {
        await this.$router.push({ path: '/profile/dashboard/' })
        return
      }
      if (this.$auth.user['call_center'] || this.$auth.user['analyst']) {
        await this.$router.push({ path: '/call-analytics/summary/' })
        return
      }
    },
  },
  created() {
    if (this.socialError && process.client) this.$toast.error('You are authorized in admin panel')
  },
  async asyncData({ store, $axios, route, $auth, redirect }) {
    await $auth.logout()
    if (route.params.vue) {
      const data = { signature: route.params.vue }
      const respData = await $axios
        .$post(`${API_V1_PREFIX}team/create-new-member-by-signature/`, data)
        .catch((error) => error)
      if (respData.status >= 400) return
      const access = respData.access
      const refresh = respData.refresh
      await $auth.setUserToken(access, refresh)
      await redirect('/profile/dashboard')
    }

    if (route.query.code && route.query.state) {
      try {
        const auth = new auth_service.Authorization()
        await auth.loginVialSocialAuth(store, route.query.code, route.query.state, redirect)
      } catch (error) {
        if (error) return { socialError: true }
      }
    }
  },
}
