
export default {
  head() {
    return {
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow'
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `${process.env.browserRootURL}` + this.$route.fullPath.slice(1)
        }
      ]
    }
  },
  name: 'empty',
}
