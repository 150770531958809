
import LoadingSpinner from '@/components/loaders/LoadingSpinner.vue'

export default {
  name: 'BaseButton',
  components: { LoadingSpinner },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
  },
  emits: {
    click: () => undefined,
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
}
