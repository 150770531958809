
export default {
  name: 'modal',
  props: {
    text: {
      type: String
    },
    isModalSuccessDownload: {
      type: Boolean,
      default: false
    },
    isModalCancelSubscription: {
      type: Boolean,
      default: false
    },
    isModalDeleteAudioVisible: {
      type: Boolean,
      default: false
    },
    isModalChangeMemberEmail: Boolean,
    default: false
  },
  components: {
    KitButton: () => import('@/components/Button')
  }
}
