
// TO BE DEPRECATED: This layout is not used anymore. Use account instead
import NotesNewUpdateModal from '@/components/modals/NotesNewUpdateModal.vue'
import getMyReleaseNotes from '@/api/notifications/release_notes/get_my_new_release_notes'

export default {
  name: 'default-layout',
  middleware: ['auth', 'ban-check'],
  components: {
    NotesNewUpdateModal,
    SideBar: () => import('@/components/SideBar'),
  },
  data: () => ({
    isUpdatesModal: false,
    releases_limit: 1,
    release: {},
  }),
  methods: {
    toggleUpdatesModal() {
      this.isUpdatesModal = !this.isUpdatesModal
    },
  },

  async fetch() {
    await getMyReleaseNotes
      .call(this, { limit: 1 })
      .then((resp) => {
        this.release = resp.results[0] || this.release
        this.toggleUpdatesModal()
      })
      .catch((error) => {
        console.error(error)
      })
  },
}
