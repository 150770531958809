
export default {
  name: 'index',
  props: {
    isDisable: {
      default: true
    },
    status: {
      default: null
    },
    isMobile: {
      default: false
    }
  }
}
