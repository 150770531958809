
import VueSlickCarousel from 'vue-slick-carousel'
import backgroundImageCallCenter from '~/assets/img/call-center-min.jpg'
import backgroundImagePsychologist from '~/assets/img/psychologist-min.jpg'
import backgroundImageSmm from '~/assets/img/smm-min.jpg'
import backgroundImageStudent from '~/assets/img/student-min.jpg'
import backgroundImagePodcaster from '~/assets/webp/podcaster-min.webp'
import backgroundImageSupportTeam from '~/assets/webp/support-team-min.webp'
export default {
  name: "index",
  props: {
    isForgotPassword: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueSlickCarousel
  },
  data: () => ({
    settings: {
      "dotsClass": "slick-dots custom-dot-class",
      "infinite": true,
      "edgeFriction": 0.35,
      "slidesToShow": 1,
      "speed": 500,
      "autoplay": true,
      "autoplaySpeed": 10000,
      "adaptiveHeight": true,
      "responsive": [
        {
          "breakpoint": 748,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1
          }
        }
      ]
    },
    backgroundImageCallCenter,
    backgroundImagePodcaster,
    backgroundImagePsychologist,
    backgroundImageSmm,
    backgroundImageStudent,
    backgroundImageSupportTeam
  }),
  methods: {
    prevReview() {
      const carousel = this.$refs.authCarousel
      carousel.prev()
    },
    nextReview() {
      const carousel = this.$refs.authCarousel
      carousel.next()
    }
  }
}
