
import NavLink from '@/components/links/NavLink.vue'
import UnderlineTab from '@/components/tabs/UnderlineTab.vue'
import UnderlineTabsBlock from '@/components/tabs/UnderlineTabsBlock.vue'
import Account from '@/pages/account/_vue.vue'
import AccountLayout from '@/layouts/account.vue'
import Breadcrumbs from '@/components/links/Breadcrumbs.vue'

export default {
  name: 'transcript-details',
  middleware: ['auth', 'ban-check'],
  components: { AccountLayout, Account, UnderlineTabsBlock, UnderlineTab, NavLink, Breadcrumbs },
}
