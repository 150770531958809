
import NavLink from '@/components/links/NavLink.vue'
import UnderlineTab from '@/components/tabs/UnderlineTab.vue'
import UnderlineTabsBlock from '@/components/tabs/UnderlineTabsBlock.vue'
import Account from '@/pages/account/_vue.vue'
import AccountLayout from '@/layouts/account.vue'
import Breadcrumbs from '@/components/links/Breadcrumbs.vue'
import BaseLayout from '@/layouts/base.vue'

export default {
  name: 'shared-transcript-details',
  components: { BaseLayout, AccountLayout, Account, UnderlineTabsBlock, UnderlineTab, NavLink, Breadcrumbs },
}
