
import BaseButton from '@/components/buttons/BaseButton.vue'

export default {
  name: 'XButton',
  components: { BaseButton },
  emits: {
    click: () => undefined,
  },
}
