
import {mapGetters} from "vuex";

export default {
  name: "auth",
  computed: {
    ...mapGetters(['isActiveMobile']),
  },
  head() {
    return {
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow'
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `${process.env.browserRootURL}` + this.$route.fullPath.slice(1)
        }
      ]
    }
  },
}
