
import { mapGetters } from 'vuex'
import NavLink from '@/components/links/NavLink.vue'

export default {
  name: 'sidebar',
  components: { NavLink },
  data: () => ({
    isShowModal: true,
  }),
  computed: {
    ...mapGetters({
      user: 'loggedInUser',
    }),
    menu() {
      return [
        {
          title: 'Dashboard',
          link: '/profile/dashboard',
          isActive: this.$route.name === 'profile-dashboard',
          isVisible: this.$auth.user['is_main_user'] || this.$auth.user['is_sub_user'],
          svg:
            '<g clip-path="url(#clip0)">\n' +
            '<path d="M16.5412 7.39418C16.5408 7.39379 16.5404 7.3934 16.54 7.39301L9.60535 0.458618C9.30976 0.162903 8.91677 0 8.49875 0C8.08073 0 7.68774 0.162773 7.39202 0.458488L0.461001 7.38938C0.458666 7.39172 0.456331 7.39418 0.453997 7.39651C-0.152998 8.00701 -0.15196 8.99753 0.45698 9.60647C0.735186 9.8848 1.10263 10.046 1.49549 10.0629C1.51144 10.0644 1.52752 10.0652 1.54373 10.0652H1.82012V15.1685C1.82012 16.1784 2.64177 17 3.65187 17H6.36493C6.63989 17 6.86298 16.777 6.86298 16.502V12.501C6.86298 12.0402 7.23781 11.6653 7.69863 11.6653H9.29887C9.75969 11.6653 10.1345 12.0402 10.1345 12.501V16.502C10.1345 16.777 10.3575 17 10.6326 17H13.3456C14.3557 17 15.1774 16.1784 15.1774 15.1685V10.0652H15.4337C15.8516 10.0652 16.2445 9.90244 16.5404 9.60673C17.15 8.99675 17.1502 8.00455 16.5412 7.39418Z" fill="currentColor"/>\n' +
            '</g>\n' +
            '<defs>\n' +
            '<clipPath id="clip0">\n' +
            '<rect width="17" height="17" fill="currentColor"/>\n' +
            '</clipPath>\n' +
            '</defs>',
        },
        {
          title: 'Pricing',
          link: '/dashboard/pricing',
          isActive: ['dashboard-pricing'].includes(this.$route.name),
          isVisible: this.$auth.user['is_main_user'],
          svg: '<path d="M14.5104 2.48957C12.905 0.884166 10.7705 0 8.5 0C6.22957 0 4.09504 0.884166 2.4896 2.48957C0.884166 4.09501 0 6.22954 0 8.5C0 10.7705 0.884133 12.905 2.4896 14.5104C4.09504 16.1158 6.22957 17 8.5 17C10.7705 17 12.905 16.1158 14.5104 14.5104C16.1159 12.905 17 10.7705 17 8.5C17 6.22954 16.1159 4.09501 14.5104 2.48957ZM9.03351 12.1988H8.99805V13.3012C8.99805 13.5763 8.77509 13.7993 8.5 13.7993C8.22498 13.7993 8.00195 13.5763 8.00195 13.3012V12.1988H6.89964C6.62462 12.1988 6.4016 11.9758 6.4016 11.7008C6.4016 11.4257 6.62462 11.2027 6.89964 11.2027H9.03354C9.64136 11.2027 10.1359 10.7082 10.1359 10.1004C10.1359 9.49257 9.64132 8.99805 9.03351 8.99805H7.96653C6.80946 8.99805 5.86812 8.0567 5.86812 6.89964C5.86812 5.74255 6.8095 4.80121 7.96653 4.80121H8.00195V3.69886C8.00195 3.42381 8.22498 3.20081 8.5 3.20081C8.77509 3.20081 8.99805 3.42381 8.99805 3.69886V4.80121H10.1004C10.3754 4.80121 10.5985 5.0242 10.5985 5.29925C10.5985 5.57431 10.3754 5.7973 10.1004 5.7973H7.96653C7.35868 5.7973 6.86421 6.29179 6.86421 6.89964C6.86421 7.50746 7.35871 8.00195 7.96653 8.00195H9.03351C10.1906 8.00195 11.1319 8.94333 11.1319 10.1004C11.1319 11.2575 10.1906 12.1988 9.03351 12.1988Z" fill="currentColor"/>\n',
        },
        {
          title: 'Settings',
          link: '/settings',
          isActive: ['settings'].includes(this.$route.name),
          isVisible: true,
          svg: '<path d="M17 8.5C17 7.86777 16.5444 7.19374 15.9828 6.99494C15.4212 6.79614 14.8768 6.30932 14.7183 5.92541C14.5599 5.54151 14.601 4.81093 14.8574 4.27283C15.1135 3.73473 14.9582 2.93566 14.5111 2.48853C14.0643 2.0414 13.2653 1.88651 12.7268 2.14256C12.1887 2.39862 11.4592 2.44041 11.0763 2.282C10.6935 2.12359 10.2063 1.57847 10.0068 1.01684C9.80696 0.455207 9.13223 0 8.5 0C7.86777 0 7.19304 0.455207 6.99318 1.01684C6.79333 1.57847 6.30651 2.12359 5.92366 2.282C5.54081 2.44041 4.81093 2.39862 4.27283 2.14256C3.73473 1.88651 2.93566 2.0414 2.48853 2.48853C2.0414 2.93566 1.88651 3.73438 2.14256 4.27283C2.39862 4.81128 2.43936 5.54116 2.28025 5.92471C2.12114 6.30826 1.57847 6.79614 1.01684 6.99494C0.455207 7.19374 0 7.86777 0 8.5C0 9.13223 0.455207 9.80696 1.01684 10.0068C1.57847 10.2063 2.12184 10.6942 2.28095 11.077C2.44006 11.4595 2.39862 12.1891 2.14256 12.7272C1.88651 13.2653 2.0414 14.0643 2.48853 14.5115C2.93566 14.9582 3.73438 15.1135 4.27283 14.8574C4.81128 14.6014 5.54081 14.5599 5.92366 14.7187C6.30686 14.8775 6.79333 15.424 6.99318 15.9846C7.19304 16.5451 7.86777 17 8.5 17C9.13223 17 9.80696 16.5451 10.0068 15.9846C10.2063 15.424 10.6928 14.8782 11.0756 14.7187C11.4585 14.5596 12.1891 14.6014 12.7268 14.8578C13.2653 15.1138 14.0643 14.9586 14.5111 14.5118C14.9582 14.0647 15.1135 13.2656 14.8574 12.7275C14.6014 12.1891 14.5592 11.4595 14.7176 11.0767C14.8761 10.6938 15.4215 10.2067 15.9832 10.0072C16.5448 9.80696 17 9.13223 17 8.5ZM8.5 11.619C6.77541 11.619 5.38099 10.2246 5.38099 8.50351C5.38099 6.77892 6.77541 5.3845 8.5 5.3845C10.2207 5.3845 11.6155 6.77892 11.6155 8.50351C11.6155 10.2246 10.2211 11.619 8.5 11.619Z" fill="currentColor"/>\n',
        },
        {
          title: 'Team',
          link: '/team',
          isActive: ['team'].includes(this.$route.name),
          isVisible: this.$auth.user['is_main_user'],
          svg:
            '<path d="M14.5462 9.0752H13.2314C13.3655 9.44209 13.4387 9.83804 13.4387 10.2507V15.2196C13.4387 15.3916 13.4087 15.5568 13.3542 15.7104H15.5277C16.3396 15.7104 17.0002 15.0498 17.0002 14.238V11.5292C17.0002 10.1761 15.8993 9.0752 14.5462 9.0752Z" fill="currentColor"/>\n' +
            '<path d="M3.56154 10.2507C3.56154 9.83801 3.63475 9.44209 3.76876 9.0752H2.45405C1.10089 9.0752 0 10.1761 0 11.5292V14.238C0 15.0499 0.660511 15.7104 1.47243 15.7104H3.64598C3.59149 15.5568 3.56154 15.3916 3.56154 15.2196V10.2507Z" fill="currentColor"/>\n' +
            '<path d="M10.0026 7.79639H6.99701C5.64385 7.79639 4.54297 8.89727 4.54297 10.2504V15.2193C4.54297 15.4903 4.76271 15.7101 5.03378 15.7101H11.9659C12.2369 15.7101 12.4567 15.4904 12.4567 15.2193V10.2504C12.4567 8.89727 11.3558 7.79639 10.0026 7.79639Z" fill="currentColor"/>\n' +
            '<path d="M8.50012 1.28955C6.87277 1.28955 5.54883 2.61349 5.54883 4.24088C5.54883 5.34472 6.15807 6.30878 7.05781 6.81493C7.48457 7.05499 7.97658 7.19218 8.50012 7.19218C9.02367 7.19218 9.51567 7.05499 9.94243 6.81493C10.8422 6.30878 11.4514 5.34469 11.4514 4.24088C11.4514 2.61353 10.1275 1.28955 8.50012 1.28955Z" fill="currentColor"/>\n' +
            '<path d="M3.31753 4.04053C2.10047 4.04053 1.11035 5.03065 1.11035 6.24771C1.11035 7.46477 2.10047 8.45489 3.31753 8.45489C3.62626 8.45489 3.92024 8.39094 4.18729 8.27599C4.64901 8.07721 5.02972 7.72532 5.2653 7.28455C5.43065 6.97519 5.52472 6.62227 5.52472 6.24771C5.52472 5.03068 4.5346 4.04053 3.31753 4.04053Z" fill="currentColor"/>\n' +
            '<path d="M13.6823 4.04053C12.4652 4.04053 11.4751 5.03065 11.4751 6.24771C11.4751 6.62231 11.5692 6.97522 11.7345 7.28455C11.9701 7.72535 12.3508 8.07724 12.8125 8.27599C13.0796 8.39094 13.3736 8.45489 13.6823 8.45489C14.8993 8.45489 15.8895 7.46477 15.8895 6.24771C15.8895 5.03065 14.8993 4.04053 13.6823 4.04053Z" fill="currentColor"/>',
        },
        {
          title: 'Transactions',
          link: '/transactions',
          isActive: ['transactions'].includes(this.$route.name),
          isVisible: this.$auth.user['is_main_user'],
          svg:
            '<g clip-path="url(#clip0_4815_11283)">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.28906C0 4.16189 0.447767 3.08089 1.2448 2.28386C2.04183 1.48683 3.12283 1.03906 4.25 1.03906H12.1429C12.3039 1.03906 12.4583 1.10303 12.5722 1.21689C12.686 1.33075 12.75 1.48518 12.75 1.64621V3.34378H4.07393C3.87265 3.34378 3.67961 3.42374 3.53729 3.56606C3.39496 3.70839 3.315 3.90143 3.315 4.10271C3.315 4.30399 3.39496 4.49702 3.53729 4.63935C3.67961 4.78168 3.87265 4.86163 4.07393 4.86163H13.2843C13.3086 4.86163 13.3316 4.86042 13.3547 4.85799H13.6146C14.0976 4.85799 14.5609 5.04989 14.9025 5.39147C15.2441 5.73306 15.436 6.19635 15.436 6.67942V7.79171H11.8539C10.8533 7.79171 9.72886 8.55063 9.72886 9.84992V12.0089C9.72886 13.3082 10.8533 14.0671 11.8539 14.0671H15.436V15.1794C15.436 15.6625 15.2441 16.1258 14.9025 16.4674C14.5609 16.8089 14.0976 17.0008 13.6146 17.0008H1.82143C1.33836 17.0008 0.875068 16.8089 0.533484 16.4674C0.1919 16.1258 0 15.6625 0 15.1794L0 5.28906ZM11.8551 9.30956H16.1051C16.4402 9.30956 16.7122 9.55242 16.7122 9.84992V12.0089C16.7122 12.3076 16.4402 12.5493 16.1051 12.5493H11.8551C11.5199 12.5493 11.2479 12.3064 11.2479 12.0089V9.84992C11.2479 9.55121 11.5199 9.30956 11.8551 9.30956Z" fill="currentColor"/>\n' +
            '</g>\n' +
            '<defs>\n' +
            '<clipPath id="clip0_4815_11283">\n' +
            '<rect width="17" height="17" fill="currentColor"/>\n' +
            '</clipPath>\n' +
            '</defs>',
        },
        {
          title: 'Affiliates',
          link: '/affiliates',
          isActive: ['affiliates'].includes(this.$route.name),
          isVisible: this.$auth.user['is_main_user'],
          svg:
            '<path d="M6.35425 6.10402L5.32014 5.06921C5.44059 4.83751 5.5151 4.57517 5.5151 4.29215C5.5151 3.39521 4.82895 2.6687 3.98148 2.6687C3.13401 2.6687 2.44678 3.39483 2.44678 4.29215C2.44678 5.18947 3.13401 5.91636 3.98148 5.91636C4.28025 5.91636 4.55659 5.82215 4.79242 5.66589L5.82508 6.69955C5.98133 6.48087 6.15748 6.28057 6.35425 6.10402Z" fill="currentColor"/>\n' +
            '<path d="M10.7282 5.97445L12.1023 3.52607C12.2376 3.56705 12.3779 3.59654 12.5251 3.59654C13.374 3.59654 14.0602 2.86964 14.0602 1.97232C14.0602 1.07539 13.374 0.348877 12.5251 0.348877C11.6777 0.348877 10.9919 1.075 10.9919 1.97232C10.9919 2.41773 11.1615 2.82024 11.4357 3.11398L10.0659 5.55547C10.3014 5.66845 10.5235 5.80939 10.7282 5.97445Z" fill="currentColor"/>\n' +
            '<path d="M15.467 8.02417C14.8073 8.02417 14.2484 8.46804 14.0318 9.08694L12.1227 8.8874C12.1227 8.90272 12.1249 8.91728 12.1249 8.93221C12.1249 9.1957 12.0974 9.45306 12.0479 9.70162L13.9562 9.90115C14.0716 10.6763 14.702 11.2707 15.467 11.2707C16.3141 11.2707 16.9999 10.5446 16.9999 9.64762C16.9999 8.75106 16.3141 8.02417 15.467 8.02417Z" fill="currentColor"/>\n' +
            '<path d="M10.1988 14.4092L9.77199 12.4346C9.5311 12.5234 9.28044 12.587 9.01893 12.6188L9.44646 14.5938C8.95636 14.8653 8.61816 15.4019 8.61816 16.0284C8.61816 16.9254 9.30395 17.6511 10.1529 17.6511C11 17.6511 11.6858 16.925 11.6858 16.0284C11.6868 15.148 11.0242 14.4353 10.1988 14.4092Z" fill="currentColor"/>\n' +
            '<path d="M5.19296 9.71606L2.81657 10.559C2.5424 10.1155 2.07038 9.82138 1.53326 9.82138C0.685787 9.82138 0 10.5479 0 11.4448C0 12.3429 0.685787 13.0694 1.53326 13.0694C2.38 13.0694 3.0676 12.3429 3.0676 11.4448C3.0676 11.4073 3.06 11.3728 3.05711 11.3361L5.44108 10.4908C5.33366 10.2453 5.2483 9.98683 5.19296 9.71606Z" fill="currentColor"/>\n' +
            '<path d="M8.61919 11.7167C10.0717 11.7167 11.2491 10.47 11.2491 8.9321C11.2491 7.39419 10.0717 6.14746 8.61919 6.14746C7.16672 6.14746 5.98926 7.39419 5.98926 8.9321C5.98926 10.47 7.16672 11.7167 8.61919 11.7167Z" fill="currentColor"/>',
        },
        {
          title: 'Support',
          link: '/support',
          isActive: ['support'].includes(this.$route.name),
          isVisible: true,
          svg:
            '<path d="M4.51562 6.97266C3.69172 6.97266 3.02148 7.64289 3.02148 8.4668V12.5176C3.02148 13.3415 3.69172 14.0117 4.51562 14.0117C5.33953 14.0117 6.00977 13.3415 6.00977 12.5176V8.4668C6.00977 7.64289 5.33953 6.97266 4.51562 6.97266Z" fill="currentColor"/>\n' +
            '<path d="M2.02539 8.46685C2.02539 8.32318 2.04435 8.18439 2.06782 8.04736C1.46758 8.24137 1.0293 8.79892 1.0293 9.46295V11.5215C1.0293 12.1856 1.46758 12.7431 2.06782 12.9371C2.04435 12.8001 2.02539 12.6613 2.02539 12.5176V8.46685Z" fill="currentColor"/>\n' +
            '<path d="M12.4844 6.97266C11.6605 6.97266 10.9902 7.64289 10.9902 8.4668V12.5176C10.9902 13.3415 11.6605 14.0117 12.4844 14.0117C12.6341 14.0117 12.776 13.9829 12.9124 13.9417C12.727 14.5563 12.1625 15.0078 11.4883 15.0078H9.9024C9.69611 14.4293 9.14846 14.0117 8.5 14.0117C7.6761 14.0117 7.00586 14.682 7.00586 15.5059C7.00586 16.3298 7.6761 17 8.5 17C9.14846 17 9.69611 16.5824 9.9024 16.0039H11.4883C12.8613 16.0039 13.9785 14.8867 13.9785 13.5137V12.5176V8.4668C13.9785 7.64289 13.3083 6.97266 12.4844 6.97266Z" fill="currentColor"/>\n' +
            '<path d="M14.9321 8.04736C14.9556 8.18439 14.9746 8.32318 14.9746 8.46685V12.5176C14.9746 12.6613 14.9556 12.8001 14.9321 12.9371C15.5324 12.7431 15.9707 12.1856 15.9707 11.5215V9.46295C15.9707 8.79892 15.5324 8.24137 14.9321 8.04736Z" fill="currentColor"/>\n' +
            '<path d="M8.5 0C4.38042 0 1.0293 3.35113 1.0293 7.4707V7.48342C1.32603 7.25877 1.66935 7.09438 2.0482 7.02077C2.28099 3.66068 5.08187 0.996094 8.5 0.996094C11.9181 0.996094 14.719 3.66068 14.9518 7.02073C15.3306 7.09435 15.6739 7.25877 15.9707 7.48339V7.4707C15.9707 3.35113 12.6196 0 8.5 0Z" fill="currentColor"/>',
        },
        {
          title: 'Call Analytics',
          link: '/call-analytics/summary/',
          isActive: [
            'call-analytics-summary',
            'call-analytics-keywords',
            'call-analytics-call_id-analytics',
            'call-analytics-call_id-details',
            'call-analytics-call_id-transcript',
            'call-analytics-agent',
          ].includes(this.$route.name),
          isVisible: this.$auth.user['call_center'] || this.$auth.user['analyst'],
          svg:
            '<g clip-path="url(#clip0_4815_11295)">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.74105 0.0324643C6.40233 0.152165 5.11108 0.587624 3.97328 1.3031C2.83548 2.01858 1.88357 2.99368 1.19568 4.14836C0.507782 5.30304 0.103517 6.6044 0.0160613 7.94561C-0.0713942 9.28682 0.160453 10.6296 0.692574 11.8639C1.22469 13.0981 2.04192 14.1886 3.07717 15.0458C4.11242 15.9029 5.33619 16.5024 6.64803 16.7949C7.95987 17.0875 9.32238 17.0647 10.6237 16.7286C11.9251 16.3925 13.1282 15.7525 14.1343 14.8613L7.97783 9.04975C7.90319 8.97867 7.84381 8.89313 7.80331 8.79835C7.7628 8.70357 7.74203 8.60153 7.74226 8.49846L7.74105 0.0324643ZM15.1773 13.7587C16.1253 12.556 16.7306 11.1194 16.9294 9.60094C17.1283 8.08247 16.9131 6.5385 16.3068 5.1322C15.7005 3.72591 14.7256 2.50954 13.485 1.61166C12.2444 0.713773 10.7842 0.167733 9.25891 0.03125V8.17182L15.1773 13.7587Z" fill="currentColor"/>\n' +
            '</g>\n' +
            '<defs>\n' +
            '<clipPath id="clip0_4815_11295">\n' +
            '<rect width="17" height="17" fill="currentColor"/>\n' +
            '</clipPath>\n' +
            '</defs>',
        },
      ]
    },
    percentsUsedTrans() {
      if (this.user) {
        const { used_trans_time_percents } = this.$store.getters.loggedInUser
        return used_trans_time_percents || 0
      }
    },
    isVisibleUpgradeBlock() {
      return this.user['is_main_user'] && this.user['subscription_plan']?.['plan_type'] === 'basic'
    },
    isVisiblePremiumBlock() {
      return !this.isVisibleUpgradeBlock
    },
  },
  watch: {},
  methods: {
    logout() {
      this.$auth.logout()
      this.$store.commit('SET_HISTORY_LIST', [])
      this.$store.commit('SET_ACTIVE_NOTIFY', false)
    },
  },
  created() {},
}
