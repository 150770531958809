
export default {
  name: 'NavLink',
  props: {
    to: {
      type: [String, Object],
    },
    target: {
      type: String,
      default: '_self',
    },
    disabled: {
      // User [disabled] attribute to disable the link in CSS
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: undefined,
    },
  },
}
