import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7066c371 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _a17e1d36 = () => interopDefault(import('../pages/activation/index.vue' /* webpackChunkName: "pages/activation/index" */))
const _16ba2468 = () => interopDefault(import('../pages/affiliate-program.vue' /* webpackChunkName: "pages/affiliate-program" */))
const _9e6befdc = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _080177f1 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _7380008f = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _2bfdabf0 = () => interopDefault(import('../pages/call-center.vue' /* webpackChunkName: "pages/call-center" */))
const _b22eaf92 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _7d20d57b = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _83e8a302 = () => interopDefault(import('../pages/editing/index.vue' /* webpackChunkName: "pages/editing/index" */))
const _c5327d0c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _6e967d06 = () => interopDefault(import('../pages/format/index.vue' /* webpackChunkName: "pages/format/index" */))
const _706a5b43 = () => interopDefault(import('../pages/formats/index.vue' /* webpackChunkName: "pages/formats/index" */))
const _4eafb82a = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _4c30883a = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6995471e = () => interopDefault(import('../pages/professions/index.vue' /* webpackChunkName: "pages/professions/index" */))
const _879689c0 = () => interopDefault(import('../pages/recommend.vue' /* webpackChunkName: "pages/recommend" */))
const _3c0ddfea = () => interopDefault(import('../pages/refund-policy.vue' /* webpackChunkName: "pages/refund-policy" */))
const _fcdf209c = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _028a4b01 = () => interopDefault(import('../pages/register-app-sumo/index.vue' /* webpackChunkName: "pages/register-app-sumo/index" */))
const _6674995b = () => interopDefault(import('../pages/register-edu/index.vue' /* webpackChunkName: "pages/register-edu/index" */))
const _652557ba = () => interopDefault(import('../pages/register-stack-social/index.vue' /* webpackChunkName: "pages/register-stack-social/index" */))
const _ac036b5e = () => interopDefault(import('../pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _5cf7c59c = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _55041ff6 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _75a99cec = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _3a1c5c6b = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _060599e4 = () => interopDefault(import('../pages/transactions/index.vue' /* webpackChunkName: "pages/transactions/index" */))
const _56ddf21a = () => interopDefault(import('../pages/voice-transcription-software-tester.vue' /* webpackChunkName: "pages/voice-transcription-software-tester" */))
const _2863d944 = () => interopDefault(import('../pages/business/lecture-transcription-software.vue' /* webpackChunkName: "pages/business/lecture-transcription-software" */))
const _928546ce = () => interopDefault(import('../pages/call-analytics/agent.vue' /* webpackChunkName: "pages/call-analytics/agent" */))
const _17448b74 = () => interopDefault(import('../pages/call-analytics/keywords.vue' /* webpackChunkName: "pages/call-analytics/keywords" */))
const _db9dcc8c = () => interopDefault(import('../pages/call-analytics/summary.vue' /* webpackChunkName: "pages/call-analytics/summary" */))
const _00e51972 = () => interopDefault(import('../pages/dashboard/pricing/index.vue' /* webpackChunkName: "pages/dashboard/pricing/index" */))
const _07cf61dc = () => interopDefault(import('../pages/profile/dashboard.vue' /* webpackChunkName: "pages/profile/dashboard" */))
const _2620584d = () => interopDefault(import('../pages/resetpassword/restore.vue' /* webpackChunkName: "pages/resetpassword/restore" */))
const _5d94475f = () => interopDefault(import('../pages/team/details/index.vue' /* webpackChunkName: "pages/team/details/index" */))
const _dfc2c394 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _30ed7aa2 = () => interopDefault(import('../pages/blog/authors/_slug.vue' /* webpackChunkName: "pages/blog/authors/_slug" */))
const _eaea5f96 = () => interopDefault(import('../pages/account/_vue.vue' /* webpackChunkName: "pages/account/_vue" */))
const _064ad6a9 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _66ad148a = () => interopDefault(import('../pages/business/_slug/index.vue' /* webpackChunkName: "pages/business/_slug/index" */))
const _6cdfdbbe = () => interopDefault(import('../pages/format/_slug.vue' /* webpackChunkName: "pages/format/_slug" */))
const _6eb3b9fb = () => interopDefault(import('../pages/formats/_slug.vue' /* webpackChunkName: "pages/formats/_slug" */))
const _39d0f3d9 = () => interopDefault(import('../pages/professions/_slug/index.vue' /* webpackChunkName: "pages/professions/_slug/index" */))
const _0813e9f0 = () => interopDefault(import('../pages/call-analytics/_call_id/analytics.vue' /* webpackChunkName: "pages/call-analytics/_call_id/analytics" */))
const _88695570 = () => interopDefault(import('../pages/call-analytics/_call_id/assistant.vue' /* webpackChunkName: "pages/call-analytics/_call_id/assistant" */))
const _16fe0f2c = () => interopDefault(import('../pages/call-analytics/_call_id/details.vue' /* webpackChunkName: "pages/call-analytics/_call_id/details" */))
const _52b4eaca = () => interopDefault(import('../pages/transcripts/_id/assistant.vue' /* webpackChunkName: "pages/transcripts/_id/assistant" */))
const _95b97746 = () => interopDefault(import('../pages/transcripts/_id/edit.vue' /* webpackChunkName: "pages/transcripts/_id/edit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _7066c371,
    name: "about"
  }, {
    path: "/activation",
    component: _a17e1d36,
    name: "activation"
  }, {
    path: "/affiliate-program",
    component: _16ba2468,
    name: "affiliate-program"
  }, {
    path: "/affiliates",
    component: _9e6befdc,
    name: "affiliates"
  }, {
    path: "/blog",
    component: _080177f1,
    name: "blog"
  }, {
    path: "/business",
    component: _7380008f,
    name: "business"
  }, {
    path: "/call-center",
    component: _2bfdabf0,
    name: "call-center"
  }, {
    path: "/contact-us",
    component: _b22eaf92,
    name: "contact-us"
  }, {
    path: "/dashboard",
    component: _7d20d57b,
    name: "dashboard"
  }, {
    path: "/editing",
    component: _83e8a302,
    name: "editing"
  }, {
    path: "/faq",
    component: _c5327d0c,
    name: "faq"
  }, {
    path: "/format",
    component: _6e967d06,
    name: "format"
  }, {
    path: "/formats",
    component: _706a5b43,
    name: "formats"
  }, {
    path: "/pricing",
    component: _4eafb82a,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _4c30883a,
    name: "privacy-policy"
  }, {
    path: "/professions",
    component: _6995471e,
    name: "professions"
  }, {
    path: "/recommend",
    component: _879689c0,
    name: "recommend"
  }, {
    path: "/refund-policy",
    component: _3c0ddfea,
    name: "refund-policy"
  }, {
    path: "/register",
    component: _fcdf209c,
    name: "register"
  }, {
    path: "/register-app-sumo",
    component: _028a4b01,
    name: "register-app-sumo"
  }, {
    path: "/register-edu",
    component: _6674995b,
    name: "register-edu"
  }, {
    path: "/register-stack-social",
    component: _652557ba,
    name: "register-stack-social"
  }, {
    path: "/resetpassword",
    component: _ac036b5e,
    name: "resetpassword"
  }, {
    path: "/settings",
    component: _5cf7c59c,
    name: "settings"
  }, {
    path: "/support",
    component: _55041ff6,
    name: "support"
  }, {
    path: "/team",
    component: _75a99cec,
    name: "team"
  }, {
    path: "/terms",
    component: _3a1c5c6b,
    name: "terms"
  }, {
    path: "/transactions",
    component: _060599e4,
    name: "transactions"
  }, {
    path: "/voice-transcription-software-tester",
    component: _56ddf21a,
    name: "voice-transcription-software-tester"
  }, {
    path: "/business/lecture-transcription-software",
    component: _2863d944,
    name: "business-lecture-transcription-software"
  }, {
    path: "/call-analytics/agent",
    component: _928546ce,
    name: "call-analytics-agent"
  }, {
    path: "/call-analytics/keywords",
    component: _17448b74,
    name: "call-analytics-keywords"
  }, {
    path: "/call-analytics/summary",
    component: _db9dcc8c,
    name: "call-analytics-summary"
  }, {
    path: "/dashboard/pricing",
    component: _00e51972,
    name: "dashboard-pricing"
  }, {
    path: "/profile/dashboard",
    component: _07cf61dc,
    name: "profile-dashboard"
  }, {
    path: "/resetpassword/restore",
    component: _2620584d,
    name: "resetpassword-restore"
  }, {
    path: "/team/details",
    component: _5d94475f,
    name: "team-details"
  }, {
    path: "/",
    component: _dfc2c394,
    name: "index"
  }, {
    path: "/blog/authors/:slug?",
    component: _30ed7aa2,
    name: "blog-authors-slug"
  }, {
    path: "/account/:vue?",
    component: _eaea5f96,
    name: "account-vue"
  }, {
    path: "/blog/:slug",
    component: _064ad6a9,
    name: "blog-slug"
  }, {
    path: "/business/:slug",
    component: _66ad148a,
    name: "business-slug"
  }, {
    path: "/format/:slug",
    component: _6cdfdbbe,
    name: "format-slug"
  }, {
    path: "/formats/:slug",
    component: _6eb3b9fb,
    name: "formats-slug"
  }, {
    path: "/professions/:slug",
    component: _39d0f3d9,
    name: "professions-slug"
  }, {
    path: "/call-analytics/:call_id?/analytics",
    component: _0813e9f0,
    name: "call-analytics-call_id-analytics"
  }, {
    path: "/call-analytics/:call_id?/assistant",
    component: _88695570,
    name: "call-analytics-call_id-assistant"
  }, {
    path: "/call-analytics/:call_id?/details",
    component: _16fe0f2c,
    name: "call-analytics-call_id-details"
  }, {
    path: "/transcripts/:id?/assistant",
    component: _52b4eaca,
    name: "transcripts-id-assistant"
  }, {
    path: "/transcripts/:id?/edit",
    component: _95b97746,
    name: "transcripts-id-edit"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
