
import NavLink from '@/components/links/NavLink.vue'
import { concatUrls } from '@/utils/url-utils'

export default {
  name: 'BreadCrumbs',
  components: { NavLink },
  props: {
    items: {
      type: Array,
      default: () => [], // {path: '/blog/posts/', name: 'Blog'},
    },
  },
  computed: {
    jsonldString() {
      const jsonld = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: this.items.map((item, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: item.name,
          ...(item.path && { item: concatUrls(process.env.domain, item.path) }),
        })),
      }
      return JSON.stringify(jsonld)
    },
  },
}
