// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/svg/check-default.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-box{padding:15px 30px!important;width:870px}.notes-title{color:var(--cl-neutral-primary);font-size:var(--header-xs-font-size);font-weight:700;text-align:center}.image-box{align-items:center;display:flex;justify-content:center;margin:20px 0 30px}.update-container h2,.update-container h3,.update-container h4,.update-container h5,.update-container h6{color:var(--cl-neutral-primary);font-size:var(--text-m-font-size);font-weight:700}.update-container h2:before,.update-container h3:before,.update-container h4:before,.update-container h5:before,.update-container h6:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;content:\"\";display:inline-block;height:20px;margin-right:10px;width:20px}.update-container p,.update-container span{color:var(--cl-neutral-secondary);font-weight:300}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
