import {API_V1_PREFIX} from "@/constants/api";

export const state = () => ({
  groupSubscriptionPlans: null,
  standardSubscriptionPlans: null
})

export const mutations = {
  SET_GROUP_PLANS(state, payload) {
    state.groupSubscriptionPlans = payload
  },
  SET_STANDARD_PLANS(state, payload) {
    state.standardSubscriptionPlans = payload
  }
}

export const actions = {
  async getStandardSubscriptionPlans({commit}) {
    try {
      const subscriptions = await this.$axios.$get(`${API_V1_PREFIX}payment/get-standard-subscription-plans/`)
      if (subscriptions) commit('SET_STANDARD_PLANS', subscriptions)
    } catch (e) {
      console.error(e)
    }
  },

  async getGroupSubscriptionPlans({commit}) {
    try {
      const subscriptions = await this.$axios.$get(`${API_V1_PREFIX}payment/get-group-subscription-plans/`)
      if (subscriptions) commit('SET_GROUP_PLANS', subscriptions)
    } catch (e) {
      console.error(e)
    }
  }


}


export const getters = {
  groupSubscriptionPlans: state => state.groupSubscriptionPlans,
  standardSubscriptionPlans: state => state.standardSubscriptionPlans,
  premiumSubscriptionPlanPrice: state => {
    const standartPlans = state.standardSubscriptionPlans
    const premium = standartPlans.find(plan => plan.plan_type === "premium")
    if (premium) {
      return premium.subscription_price
    } else {
      return '25.00'
    }
  }
}
