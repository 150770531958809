
export default {
  name: 'ProjectSocialNetworks',
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },

  computed: {
    socialLinks() {
      return {
        facebook: this.contacts.find((item) => item.type === 'facebook'),
        twitter: this.contacts.find((item) => item.type === 'twitter'),
        linkedin: this.contacts.find((item) => item.type === 'linkedin'),
      }
    },
  },

  methods: {},
}
