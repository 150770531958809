
export default {
  name: 'UnderlineTab',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: () => undefined,
  },
}
