export function debounce(wait, func) {
  let timeout
  let isFirstCall = true
  return function wrapper(...args) {
    const context = this
    if (isFirstCall) {
      isFirstCall = false
      return func.apply(context, args)
    }
    const later = () => {
      timeout = null
      isFirstCall = true
      return func.apply(context, args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export function runOnClientOnly(func) {
  if (process.client) {
    return func()
  }
  return undefined
}
