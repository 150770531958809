import { API_V1_PREFIX } from '@/constants/api'

const frequencyFormatsTemplate = ['MP3', 'AMR', 'OGG', 'SWF', 'WAV', 'WMA', 'XSPF']
export const state = () => ({
  history_list: [],
  contacts: [],
  formats: [],
  frequencyFormats: [],
  businessFormats: [],
  professionFormats: [],
  reviews: [],
  questions: [],
  identifierAppSumo: '',
  identifierStackSocial: '',
  isAllFormatsView: false,
  displayedFormats: [],
  isActiveMobile: false,
  menu: [
    {
      title: 'Home',
      link: '/',
      isActive: false,
      icon: false,
    },
    {
      title: 'Categories',
      type: 'categories',
      link: false,
      icon: true,
      isActive: false,
      showChild: false,
      child: true,
    },
    {
      title: 'Pricing',
      icon: false,
      isActive: false,
      link: '/pricing',
    },
    {
      title: 'About',
      icon: false,
      isActive: false,
      link: '/about',
    },
    {
      title: 'Call Center',
      icon: false,
      isActive: false,
      link: '/call-center',
    },
    {
      title: 'Blog',
      icon: false,
      isActive: false,
      link: '/blog/',
    },
  ],
  dropdownMenu: [
    {
      chapter: 'Formats',
      description: 'Description here',
      icon: 'icon-new-sticky-note',
      mask: 'mask-orange',
      type: 'format',
      showChild: true,
      childList: [],
    },
    {
      chapter: 'Business',
      description: 'Description here',
      icon: 'icon-bag-suitcase',
      mask: 'mask-primary',
      type: 'business',
      showChild: false,
      childList: [],
    },
    {
      chapter: 'Professions',
      description: 'Description here',
      icon: 'icon-smiley-cool',
      mask: 'mask-beige',
      type: 'professions',
      showChild: false,
      childList: [],
    },
  ],
  account: {
    isNotifiedUser: false,
  },
})

export const mutations = {
  SET_HISTORY_LIST(state, payload) {
    state.history_list = payload
  },
  SET_CONTACTS(state, payload) {
    state.contacts = payload
  },
  SET_MENU(state, payload) {
    state.menu = payload
  },
  SET_DROPDOWN_MENU(state, payload) {
    state.dropdownMenu = payload
  },
  SET_ACTIVE_NOTIFY(state, bool) {
    state.account.isNotifiedUser = bool
  },
  SET_ACTIVE_MOBILE(state, bool) {
    state.isActiveMobile = bool
  },
  SET_FORMATS(state, payload) {
    state.formats = payload
  },
  SET_DISPLAYED_FORMATS(state, payload) {
    state.displayedFormats = payload
  },
  SET_ALL_DISPLAYED_FORMATS(state, payload) {
    state.displayedFormats = [...state.displayedFormats, ...payload]
  },
  SET_ALL_FORMATS_VIEW(state, bool) {
    state.isAllFormatsView = bool
  },
  SET_REVIEWS(state, payload) {
    state.reviews = payload
  },
  SET_FAQ(state, payload) {
    state.questions = payload
  },
  SET_APP_SUMO(state, code) {
    state.identifierAppSumo = code
  },
  SET_STACK_SOCIAL(state, code) {
    state.identifierStackSocial = code
  },
  SET_BUSINESS_FORMATS(state, payload) {
    state.businessFormats = payload
    const found = state.dropdownMenu.find((item) => item.chapter === 'Business')
    const modified = payload.map((item) => {
      item.link = '/business/' + item.slug
      return item
    })
    modified.unshift({
      slug: 'business',
      link: '/business',
      description: 'More than 5 business. Easily, quickly, and effectively. Upload, transcribe, and use.',
      name: 'All Business',
    })
    found.childList = modified
  },
  SET_PROFESSION_FORMATS(state, payload) {
    state.professionFormats = payload
    const found = state.dropdownMenu.find((item) => item.chapter === 'Professions')
    const modified = payload.map((item) => {
      item.link = '/professions/' + item.slug
      return item
    })
    modified.unshift({
      slug: 'professions',
      link: '/professions',
      description: 'More than 5 professions. Easily, quickly, and effectively. Upload, transcribe, and use.',
      name: 'All Professions',
    })
    found.childList = modified
  },
  SET_FREQUENCY_FORMATS(state, payload) {
    state.frequencyFormats = payload
    const found = state.dropdownMenu.find((item) => item.chapter === 'Formats')
    const modified = payload.map((item) => {
      item.link = '/formats/' + item.slug
      return item
    })
    modified.unshift({
      slug: 'formats',
      link: '/formats',
      description: 'More than 20 formats. Easily, quickly, and effectively. Upload, transcribe, and use.',
      name: 'All Formats',
    })
    found.childList = modified
  },
}

export const actions = {
  async nuxtServerInit({ dispatch }) {
    await dispatch('getProfessionFormats')
    await dispatch('getBusinessFormats')
    await dispatch('getFormats')
    await dispatch('getReviews')
    await dispatch('getContacts')
    await dispatch('payment/getGroupSubscriptionPlans')
    await dispatch('payment/getStandardSubscriptionPlans')
  },
  async getHistoryForCurrentUser({ commit }) {
    const respData = await this.$axios.$get(`${API_V1_PREFIX}transcription/get-my-transcript-history/`)
    commit('SET_HISTORY_LIST', respData)
  },

  async getReviews({ state, commit }) {
    if (state.reviews.length) return
    try {
      const reviews = await this.$axios.$get(`${API_V1_PREFIX}markup/get-reviews/`)
      const payload = reviews.map((item, index) => {
        index ? (item.isActive = false) : (item.isActive = true)
        return item
      })
      commit('SET_REVIEWS', payload)
    } catch (error) {
      console.error(error)
    }
  },

  async getFaq({ state, commit }) {
    if (state.questions.length) return
    try {
      const respData = await this.$axios.$get(`${API_V1_PREFIX}markup/faq/`)
      const payload = respData.filter((item) => item.place !== 'pricing')
      const questions = payload.map((item) => {
        return { ...item, isActive: false }
      })
      commit('SET_FAQ', questions)
    } catch (error) {
      console.error(error)
    }
  },

  async getContacts({ state, commit }) {
    if (state.contacts.length) return
    try {
      const respData = await this.$axios.$get(`${API_V1_PREFIX}markup/get-contacts/`)
      commit('SET_CONTACTS', respData)
    } catch (error) {
      console.error(error)
    }
  },

  async getBusinessFormats({ state, commit }) {
    if (state.businessFormats.length) return
    try {
      const resp = await this.$axios.$get(`${API_V1_PREFIX}markup/get-business-formats/`)
      commit('SET_BUSINESS_FORMATS', resp)
    } catch (e) {
      console.error(e)
    }
  },

  async getProfessionFormats({ state, commit }) {
    if (state.professionFormats.length) return
    try {
      const resp = await this.$axios.$get(`${API_V1_PREFIX}markup/get-profession-formats/`)
      commit('SET_PROFESSION_FORMATS', resp)
    } catch (e) {
      console.error(e)
    }
  },

  async getFormats({ state, commit, dispatch }) {
    if (state.formats.length) return
    try {
      const respData = await this.$axios.$get(`${API_V1_PREFIX}markup/get-formats/`)
      respData.sort((a, b) => a.id - b.id)
      const frequency = JSON.parse(
        JSON.stringify(respData.filter((format) => frequencyFormatsTemplate.includes(format.name))),
      )
      frequency.forEach((item) => {
        item.name = item.name + ' to Text'
      })
      commit('SET_FORMATS', respData)
      commit('SET_ALL_FORMATS_VIEW', false)
      commit('SET_DISPLAYED_FORMATS', respData)
      commit('SET_FREQUENCY_FORMATS', frequency)
      // dispatch('getDefaultFormats', respData)
    } catch (error) {
      console.error(error)
    }
  },

  getDefaultFormats({ commit, state }, arr) {
    commit('SET_DISPLAYED_FORMATS', arr)
    commit('SET_ALL_FORMATS_VIEW', false)
  },

  addOtherFormats({ commit, state }) {
    // const payload = state.formats.filter((item, index) => index >= 7)
    // commit('SET_ALL_DISPLAYED_FORMATS', payload)
    commit('SET_ALL_FORMATS_VIEW', true)
  },

  async getServicesIdentifier({ commit }) {
    try {
      const appSumo = await this.$axios.$get(`${API_V1_PREFIX}app-sumo/get-my-code/`)
      const stackSocial = await this.$axios.$get(`${API_V1_PREFIX}stack-social/get-my-code/`)
      commit('SET_APP_SUMO', appSumo.value)
      commit('SET_STACK_SOCIAL', stackSocial.value)
    } catch (e) {
      console.error(e)
    }
  },
}

export const getters = {
  isAuthenticated: (state) => state.auth.loggedIn,
  loggedInUser: (state) => state.auth.user,
  account: (state) => state.account,
  historyList: (state) => state.history_list,
  contacts: (state) => state.contacts,
  formats: (state) => state.formats,
  displayedFormats: (state) => state.displayedFormats,
  menu: (state) => state.menu,
  dropdownMenu: (state) => state.dropdownMenu,
  isAllFormatsView: (state) => state.isAllFormatsView,
  reviews: (state) => state.reviews,
  questions: (state) => state.questions,
  identifierAppSumo: (state) => state.identifierAppSumo,
  identifierStackSocial: (state) => state.identifierStackSocial,
  businessFormats: (state) => state.businessFormats,
  professionFormats: (state) => state.professionFormats,
  frequencyFormats: (state) => state.frequencyFormats,
  isActiveMobile: (state) => state.isActiveMobile,
  possibilityUnsubscribe: (state) => {
    const user = state.auth.user
    if (user && user.subscription_plan) {
      return user.subscription_plan.plan_type !== 'basic' && user.subscription_status !== 'deleted'
    } else {
      return false
    }
  },
}
