export function concatUrls(...args) {
  // Remove double slashes, add trailing slash in the end
  let url = ''
  for (let arg of args) {
    if (url.endsWith('/') && arg.startsWith('/')) arg = arg.substring(1) // Prevent double slashes
    url += arg
    if (!url.endsWith('/')) url += '/' // Add trailing slash
  }
  return url
}
