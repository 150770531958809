import { render, staticRenderFns } from "./anonymous.vue?vue&type=template&id=1bf21e55"
import script from "./anonymous.vue?vue&type=script&lang=js"
export * from "./anonymous.vue?vue&type=script&lang=js"
import style0 from "./anonymous.vue?vue&type=style&index=0&id=1bf21e55&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SideBar: require('/code/components/SideBar/index.vue').default,Button: require('/code/components/Button/index.vue').default,ProjectSocialNetworks: require('/code/components/ProjectSocialNetworks.vue').default,Footer: require('/code/components/Footer/index.vue').default,Modal: require('/code/components/Modal/index.vue').default})
