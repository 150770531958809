
// DEPRECATED: Use anonymous layout instead
import { mapGetters } from 'vuex'

export default {
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${process.env.browserRootURL}` + this.$route.fullPath.slice(1)
        }
      ]
    }
  },
  name: 'home',
  data: () => ({
    email: '',
    showConfirmSendMessage: false,
    showErrorSendMessage: false,
    isShowMotivationModal: false,
    showDiscount: true,
    prevScrollPos: 0
  }),
  components: {
    Footer: () => import('@/components/Footer'),
    HomepageMotivationPopup: () => import('@/components/modals/HomepageMotivationPopup.vue'),
    KitButton: () => import('@/components/Button')
  },
  computed: {
    ...mapGetters(['contacts', 'menu', 'isActiveMobile'])
  },
  mounted() {
    setTimeout(() => {
      this.showMotivationModal()
    }, 8000)
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const currentScrollPos = window.scrollY
      this.showDiscount = currentScrollPos === 0
      this.prevScrollPos = currentScrollPos
    },
    hideMotivationModal() {
      this.isShowMotivationModal = false
      this.email = ''
      this.showErrorSendMessage = false
      this.showConfirmSendMessage = false
    },
    showMotivationModal() {
      if (!this.isShowMotivationModal) this.isShowMotivationModal = true
    },
    async goToRegisterPage() {
      await this.$router.push('/register')
    }
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        const payload = this.menu.map((item) => {
          if (item.hasOwnProperty('child')) {
            return {
              ...item,
              isActive: false,
              showChild: false
            }
          } else {
            return {
              ...item,
              isActive: false
            }
          }
        })
        this.$store.commit('SET_MENU', payload)
        this.$store.commit('SET_ACTIVE_MOBILE', false)
      }
    }
  }
}
