
import Sidebar from '@/components/SideBar/index.vue'
import BaseButton from '@/components/buttons/BaseButton.vue'
import { Duration } from '@/utils/date-time-utils'
import CButton from '@/components/buttons/cButton.vue'

export default {
  name: 'account',
  middleware: ['auth'],

  components: { CButton, BaseButton, Sidebar },
  data: () => ({
    isBurgerButtonActive: false,
    isSidebarActive: false,
  }),
  computed: {
    userRemainingTransTime() {
      return new Duration(this.$auth.user['remaining_trans_time'])
    },
  },
  methods: {
    onBurgerMenuButtonClick() {
      this.isBurgerButtonActive = !this.isBurgerButtonActive
      this.isSidebarActive = !this.isSidebarActive
    },
  },
}
