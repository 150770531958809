
// Please use this layout for external anonymous pages. Do not use home layout.
import NavLink from '@/components/links/NavLink.vue'
import BaseButton from '@/components/buttons/BaseButton.vue'
import DropDown from '@/components/dropdowns/DropDown.vue'
import ArrowDown from '@/components/svg/ArrowDown.vue'
import { getBusinessFormatsList } from '@/api/markup/get-business-formats-list'
import { getProfessionFormatsList } from '@/api/markup/get-profession-formats-list'
import { getFileFormatsList } from '@/api/markup/get-file-formats-list'
import { concatUrls } from '@/utils/url-utils'
import MenuButton from '@/components/buttons/MenuButton.vue'
import SideBar from '@/components/sidebars/SideBar.vue'
import Modal from '@/components/modals/Modal.vue'
import { API_V1_PREFIX } from '@/constants/api'
import { debounce } from '@/utils/function-utils'
import { getContactsList } from '@/api/markup/get-contacts-list'
import { email, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import BaseLayout from '@/layouts/base.vue'
import { formMetaFAQJson } from '@/utils/seo-meta'
export default {
  name: 'anonymous',
  components: { BaseLayout, Modal, SideBar, MenuButton, ArrowDown, DropDown, BaseButton, NavLink },
  mixins: [validationMixin],

  head() {
    return {
      __dangerouslyDisableSanitizersByTagID: {
        'software-application-ldjson': ['innerHTML'], // Отключаем санитизацию для innerHTML faq-ldjson
      },
      script: [
        {
          hid: 'software-application-ldjson',
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'SoftwareApplication',
            operatingSystem: 'Windows, Mac, Chrome, Microsoft Edge, Safari, Android, iOS',
            name: 'Rythmex',
            applicationCategory: 'WebApplication',
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '5.0',
              ratingCount: '5634',
            },
            offers: {
              '@type': 'Offer',
              price: '0',
              priceCurrency: 'USD',
            },
          }),
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: concatUrls(process.env.domain, this.$route.path),
        },
      ],
    }
  },

  data: () => ({
    isCategoriesDropDownOpen: false,
    selectedDropDownCategoryIndex: 0,
    dropdownCategories: [
      {
        chapter: 'Formats',
        description: 'Description here',
        icon: 'icon-new-sticky-note',
        mask: 'mask-orange',
        type: 'format',
        showChild: true,
        showChildMobile: false,
        isAllChildrenVisible: false,
        link: '/formats/',
        childList: [
          {
            slug: '',
            description: 'More than 20 formats. Easily, quickly, and effectively. Upload, transcribe, and use.',
            name: 'All Formats',
            is_visible_in_footer: true,
            is_visible_in_sidebar: true,
            is_visible_in_navbar: true,
          },
        ],
      },
      {
        chapter: 'Business',
        description: 'Description here',
        icon: 'icon-bag-suitcase',
        mask: 'mask-primary',
        type: 'business',
        showChild: false,
        showChildMobile: false,
        isAllChildrenVisible: true,
        link: '/business/',
        childList: [
          {
            slug: '',
            description: 'More than 5 business. Easily, quickly, and effectively. Upload, transcribe, and use.',
            name: 'All Business',
          },
        ],
      },
      {
        chapter: 'Professions',
        description: 'Description here',
        icon: 'icon-smiley-cool',
        mask: 'mask-beige',
        type: 'professions',
        showChild: false,
        showChildMobile: false,
        isAllChildrenVisible: true,
        link: '/professions/',
        childList: [
          {
            slug: '',
            description: 'More than 5 professions. Easily, quickly, and effectively. Upload, transcribe, and use.',
            name: 'All Professions',
          },
        ],
      },
    ],
    isMobileMenuButtonActive: false,
    isFirstOrderDiscountModalOpen: false,
    contactsFromServer: [],
    contactMeEmail: '',
  }),

  computed: {
    contacts() {
      return {
        email: this.contactsFromServer.find((item) => item.type === 'email'),
        tel: this.contactsFromServer.find((item) => item.type === 'tel'),
      }
    },
    categories() {
      return {
        formats: this.dropdownCategories.find((category) => category.chapter === 'Formats'),
        business: this.dropdownCategories.find((category) => category.chapter === 'Business'),
        professions: this.dropdownCategories.find((category) => category.chapter === 'Professions'),
      }
    },
    about() {
      return {
        showChildMobile: false,
        title: 'About',
        childList: [
          {
            title: 'Pricing',
            path: '/pricing/',
          },
          {
            title: 'Affiliate Program',
            path: '/affiliate-program/',
          },
          {
            title: 'Blog',
            path: '/blog/',
          },
          {
            title: 'About us',
            path: '/about/',
          },
          {
            title: 'FAQ',
            path: '/faq/',
          },
          {
            title: 'Contact Us',
            path: '/contact-us/',
          },
        ],
      }
    },
  },

  validations: {
    contactMeEmail: { required, email },
  },

  methods: {
    concatUrls,
    onDropDownCategoryMouseEnter(index) {
      this.selectedDropDownCategoryIndex = index
    },
    toggleCategoryChildrenInMobileSidebar(category) {
      category.showChildMobile = !category.showChildMobile
    },
    showFirstOrderDiscountModal() {
      this.isFirstOrderDiscountModalOpen = true
    },
    closeFirstOrderDiscountModal() {
      this.isFirstOrderDiscountModalOpen = false
    },
    sendEmailForSubscription: debounce(300, async function () {
      try {
        this.$v.contactMeEmail.$touch()
        if (this.$v.contactMeEmail.$invalid) return
        const data = { email: this.contactMeEmail }
        await this.$axios.$post(`${API_V1_PREFIX}auth/notification-subscription/email/`, data)
        this.clearField()
        this.$toast('Email successfully signed!', { id: 'subscribe-form-send-toast' })
      } catch ({ response }) {
        if (response.data && response.data.email) this.$toast.error(response.data.email.join('; '))
      }
    }),
  },

  mounted() {
    setTimeout(() => {
      this.showFirstOrderDiscountModal()
    }, 8000)
  },

  async fetch() {
    await Promise.all([
      getBusinessFormatsList
        .call(this)
        .then((response) => {
          this.dropdownCategories.find((item) => item.chapter === 'Business').childList.push(...response)
        })
        .catch((error) => {
          console.log('error', error)
        }),
      getProfessionFormatsList
        .call(this)
        .then((response) => {
          this.dropdownCategories.find((item) => item.chapter === 'Professions').childList.push(...response)
        })
        .catch((error) => {
          console.log('error', error)
        }),
      getFileFormatsList
        .call(this)
        .then((response) => {
          this.dropdownCategories.find((item) => item.chapter === 'Formats').childList.push(...response)
        })
        .catch((error) => {
          console.log('error', error)
        }),
      getContactsList.call(this).then((response) => {
        this.contactsFromServer = response
      }),
    ])
  },
}
