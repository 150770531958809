
export default {
  name: 'SideBar',
  props: {
    value: {
      // active
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    disappearing: false
  }),

  watch: {
    value(value) {
      if (value === true) {
        if (typeof document !== 'undefined') document.documentElement.classList.add('no-scroll')
      }
      if (value === false) {
        this.closeWithAnimation()
        if (typeof document !== 'undefined') document.documentElement.classList.remove('no-scroll')
      }
    }
  },

  methods: {
    emitInput(value) {
      this.$emit('input', value)
    },

    closeWithAnimation() {
      this.disappearing = true
      setTimeout(() => {
        this.emitInput(false)
        this.disappearing = false
      }, 300)
    },

    onClickOutsideMenu() {
      this.emitInput(false)
    }
  }
}
