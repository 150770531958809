
import debounce from 'lodash.debounce'
import { email, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapGetters } from 'vuex'
import { API_V1_PREFIX } from '@/constants/api'

export default {
  name: 'home-footer',
  mixins: [validationMixin],
  components: {
    Social: () => import('./Social'),
  },
  data: () => ({
    email: '',
    business: {
      isActive: false,
      title: 'Business',
    },
    professions: {
      isActive: false,
      title: 'Professions',
    },
    formats: {
      isActive: false,
      title: 'Formats',
    },
    about: {
      isActive: false,
      title: 'About',
      list: [
        {
          title: 'Pricing',
          link: '/pricing',
        },
        {
          title: 'Affiliate Program',
          link: '/affiliate-program',
        },
        {
          title: 'Blog',
          link: '/blog/',
        },
        {
          title: 'About us',
          link: '/about',
        },
        {
          title: 'FAQ',
          link: '/faq',
        },
        {
          title: 'Contact Us',
          link: '/contact-us',
        },
      ],
    },
  }),
  computed: {
    ...mapGetters(['contacts', 'businessFormats', 'professionFormats', 'frequencyFormats']),
    contactEmail() {
      return this.contacts.find((item) => item.type === 'email')
    },
    tel() {
      return this.contacts.find((item) => item.type === 'tel')
    },
    isAboutPage() {
      return this.$route.name === 'about'
    },
  },
  validations: {
    email: { required, email },
  },
  methods: {
    sendEmailForSubscription: debounce(async function () {
      try {
        this.$v.email.$touch()
        if (this.$v.email.$invalid) return
        const data = { email: this.email }
        await this.$axios.$post(`${API_V1_PREFIX}auth/notification-subscription/email/`, data)
        this.clearField()
        this.$toast('Email successfully signed!', { id: 'subscribe-form-send-toast' })
      } catch ({ response }) {
        if (response.status === 403) {
          this.$toast.error("You're authorized in admin panel!")
          return
        }
        if (response.data && response.data.email) this.$toast.error(response.data.email.join('; '))
      }
    }, 300),
    clearField() {
      this.email = ''
      this.$v.email.$reset()
    },
  },
}
