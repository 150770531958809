import { API_V1_PREFIX } from '@/constants/api'

class Authorization {
  constructor(ctx) {
    this.context = ctx
  }

  async register(data) {
    return await this.context.$axios
      .post(`${API_V1_PREFIX}auth/registration/register/`, data)
      .catch((error) => error.response)
  }

  async registerAppSumo(data) {
    return await this.context.$axios
      .post(`${API_V1_PREFIX}app-sumo/register-user/`, data)
      .catch((error) => error.response)
  }

  async registerStackSocial(data) {
    return await this.context.$axios
      .post(`${API_V1_PREFIX}stack-social/users/register-user/`, data)
      .catch((error) => error.response)
  }

  async resendRegisterActivation(email) {
    await this.context.$axios.post(`${API_V1_PREFIX}auth/registration/resend-verification/`, email)
  }

  async resetPassword(email) {
    return await this.context.$axios
      .post(`${API_V1_PREFIX}auth/users/send-reset-password-link/`, email)
      .catch((error) => error.response)
  }

  async resetPasswordConfirm(data) {
    return await this.context.$axios
      .post(`${API_V1_PREFIX}auth/users/reset-password/`, data)
      .catch((error) => error.response)
  }

  async loginVialSocialAuth(store, code, state, redirect) {
    const redirectUri = process.env.socialAuthRedirectUri
    let providerUrl = ''
    switch (store.$auth.strategy.name) {
      case 'google':
        providerUrl = `${API_V1_PREFIX}auth/jwt/obtain-by-google/`
        break
      case 'facebook':
        providerUrl = `${API_V1_PREFIX}auth/jwt/obtain-by-facebook/`
    }
    try {
      const resp = await store.$axios.$post(`${providerUrl}`, { code, state, redirect_uri: redirectUri })
      await store.$auth.setUserToken(resp.access, resp.refresh)
      await redirect('/profile/dashboard')
    } catch (error) {
      this.$toast.error('Sorry. Login via social authentication unsuccessful =(')
    }
  }
}

export default {
  Authorization,
}
