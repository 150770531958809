import { render, staticRenderFns } from "./home.vue?vue&type=template&id=08a11175&scoped=true"
import script from "./home.vue?vue&type=script&lang=js"
export * from "./home.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a11175",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/code/components/Footer/index.vue').default,Footer: require('/code/components/Footer/index.vue').default})
