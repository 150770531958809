
export default {
  name: 'cButton',
  components: {
    NavLink: () => import('@/components/links/NavLink.vue'),
  },
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
    href: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: () => undefined,
  },
}
