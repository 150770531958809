
import vClickOutside from 'v-click-outside'

export default {
  name: 'DropDown',
  directives: {
    clickOutside: vClickOutside.directive
  },

  props: {
    value: {
      // isOpen
      type: Boolean,
      default: false
    },
    horizontalPosition: {
      type: String,
      default: 'left', // or "right"
      validator: (value) => {
        return ['left', 'right'].includes(value)
      }
    },
    verticalPosition: {
      type: String,
      default: 'bottom', // or "top"
      validator: (value) => {
        return ['top', 'bottom'].includes(value)
      }
    }
  },

  computed: {
    itemSlotNames() {
      return Object.keys(this.$slots).filter((item) => item !== 'title')
    }
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value)
    },
    emitDropDownClosed() {
      this.$emit('close')
    },

    closeDropdown() {
      this.emitInput(false)
      this.emitDropDownClosed()
    },
    openDropDown() {
      this.emitInput(true)
    },
    toggleDropDown() {
      this.value ? this.closeDropdown() : this.openDropDown()
    },
    onDropDownTitleClick() {
      this.toggleDropDown()
    },
    onDropDownItemClick() {
      this.closeDropdown()
    },
    onClickOutsideOfDropDownContent() {
      this.closeDropdown()
    }
  }
}
