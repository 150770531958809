const consoleError = console.error
const consoleWarn = console.warn
const consoleLog = console.log

const formLogString = (level, ...args) => {
  const timestamp = new Date().toISOString()
  const environment = process.server ? 'SERVER' : 'CLIENT'
  let logString = `[${timestamp}] [${level.toUpperCase()}] [${environment}]`
  for (const arg of args) {
    logString += ` [${arg}]`
  }
  return logString
}

const formResponseLogString = (level, err) => {
  return formLogString(
    level,
    'RESPONSE',
    err?.response?.headers?.['x_request_id'],
    err?.config?.method,
    err?.config?.url,
    JSON.stringify(err?.config?.params),
    JSON.stringify(err?.config?.data),
    err?.response?.status,
    JSON.stringify(err?.response?.data),
  )
}

const logToFile = (message) => {
  if (process.server) {
    const { logToFile } = require('~/utils/file-logging')
    logToFile(message)
  }
  if (process.client) {
    fetch('/logging/log/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: message }),
    }).catch()
  }
}

const logError = (err, ...args) => {
  let logString = ''
  if (err?.response) {
    logString = formResponseLogString('error', err)
  } else {
    logString = formLogString('error', err)
  }
  consoleError(logString, ...args)
  logToFile(logString)
  const stack = new Error().stack
  for (const line of stack.split('\n')) {
    logToFile(line) // Log stack trace to file. In console, it is already logged.
  }
}

const logWarn = (message, ...args) => {
  const logString = formLogString('warn', message)
  consoleWarn(logString, ...args)
  logToFile(logString)
}

const logInfo = (message, ...args) => {
  const logString = formLogString('log', message)
  consoleLog(logString, ...args)
  logToFile(logString)
}

export default function () {
  // Logging on client console -> File, on server API -> Server -> File
  // Be careful to use 'error', 'warn', 'info' methods on client side. They will make a request to API.
  // Use console.log for client side only logging.

  if (console['isInitializedForLogging']) return // Prevent double initialization
  console.error = logError
  console.warn = logWarn
  console.info = logInfo
  console.log = logInfo
  console['isInitializedForLogging'] = true
}
