
import XButton from '@/components/buttons/XButton.vue'

export default {
  name: 'Modal',
  components: { XButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    input: (state) => state,
    close: () => undefined,
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (typeof document === 'undefined') return
        if (value) {
          document.documentElement.classList.add('no-scroll')
        } else {
          document.documentElement.classList.remove('no-scroll')
        }
      },
    },
  },

  methods: {
    onClickOutsideBody() {
      if (this.disabled) return
      this.$emit('input', false)
      this.$emit('close')
    },
    onCloseModalButtonClick() {
      if (this.disabled) return
      this.$emit('input', false)
      this.$emit('close')
    },
  },
}
