
export default {
  props: {
    isShowModal: {
      type: Boolean,
      default: false
    },
    release: {
      type: Object
    }
  },
  components: {
    ModalBox: () => import('@/components/modals/ModalBox.vue')
  }
}
